@import "./variables.scss";
@import "./chat/play.scss";
@import "./chat/divide.scss";

@font-face {
  font-family: "NotoR";
  font-weight: 400;
  src: url("../assets/fonts/NotoSansKR-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "NotoR";
  font-weight: 700;
  src: url("../assets/fonts/NotoSansKR-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoR";
  font-weight: 600;
  src: url("../assets/fonts/NotoSansKR-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "NotoR";
  font-weight: 300;
  src: url("../assets/fonts/NotoSansKR-Light.ttf") format("truetype");
}
@font-face {
  font-family: "NotoR";
  font-weight: 200;
  src: url("../assets/fonts/NotoSansKR-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "NotoR";
  font-weight: 800;
  src: url("../assets/fonts/NotoSansKR-ExtraBold.ttf") format("truetype");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
img {
  vertical-align: top;
}

html {
  font-family: "NotoR", sans-serif;
  font-size: 12px;
}
@media screen and (min-width: 450px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 600px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1404px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) {
  html {
    font-size: 18px;
  }
}
b,
strong {
  font-weight: 700;
}

* {
  word-break: keep-all;
  font-family: "NotoR", sans-serif;
}
.map-wrapper {
  margin-top: 1rem;
}
.rec__preview {
  ul,
  li {
    list-style: none;
    background-color: white;
  }
  .map-wrapper {
    & > div:first-child {
      border-radius: 2rem;

      .marker-box {
        display: flex;
        padding: 5px;
        background: #0475f4;
        border-radius: 2rem;
        box-shadow: 2px 4px 12px -1px #d9d9d9;
        color: white;
        font-size: 0.8rem;
        max-width: 145px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        min-width: 50px;
        border: none;
        cursor: pointer;
        .marker-icon {
          width: 1rem;
          height: 1rem;
          background: white;
          padding: 5px;
          border-radius: 50%;
          margin: auto;
        }
        .map-icon__top {
          z-index: 1;
        }
        .marker-name {
          font-weight: 700;
          margin: auto;
          font-size:0.8rem;
          padding: 0 5px;
          min-width: 100px;
        }
      }
      .marker-box:after {
        border-top: 10px solid #0475f4;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        top: 36px;
        left: 23px;
      }
    }
  }
  .jbFZec {margin-top: 1rem;}
  .jbFZec .slick-slide>div{margin-bottom: 0;margin-top: 0;}
  .rec__preview__list {
    margin-right: 5px;
    display: flex !important;
    min-height: 150px;
    border-radius: 1.2rem;
    height: fit-content;
    padding: 1.4rem;
    background-color: white;
    box-sizing: border-box;
    .label-txt {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
    img {
      transition: all ease 1s;
      cursor: pointer;
      display: block;
      border-radius: 1rem;
      flex: 1;
    }

    .label-txt {
      font-weight: 700;
      font-size: 1rem;
    }
    .color-txt {
      color: $gbe-color-green;
      font-weight: 600;
    }
    p {
      font-size: 0.8rem;
    }
    .rec__preview__summary {
      margin: 0 10px;
      padding: 1rem;
      flex: 1;
    }
    .rec__preview__detail {
      max-height: 200px;
      overflow: hidden;
      padding: 1rem;
      flex: 1;
      .rec__preview__sub {
        border-radius: 10px;
        background-color: rgba($color: $gbe-color-yellow, $alpha: 0.1);
        padding: 5px 10px;
        font-weight: 700;
        width: fit-content;
        color: $gbe-color-yellow;
      }
      .rec__preview__desc {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 5px;
      }
    }
  }
}
.naver__preview {
  .slick-slide {
    box-sizing: border-box;
    padding: 0.15rem;
    &:hover {
      .slide-wrapper {
        background-color: #2db400;
        color: #fff;
      }
    }
    .slide-wrapper {
      background-color: #fff;
      border: 2px solid #2db400;
      border-radius: 1rem;
      color: #2db400;
      overflow: hidden;
      transition: all 0.2s ease;
      height: 250px;
      padding: 1rem;
      box-sizing: border-box;
      .template__list-row {
        margin: 0;
      }
      .nv-cd__title {
        font-weight: 600;
        line-clamp: 4;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        * {
          font-weight: 600;
        }
      }
      .nv-cd__desc {
        font-size: 0.8rem;
        margin-top: 0.5rem;
        line-clamp: 4;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        * {
          font-weight: 400;
        }
      }
    }
    .template__list-row {
      padding: 0;
      background-color: transparent;
    }
  }
}

.chat-prompt__box__title {
  position: relative;
  padding: 2rem 130px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  .gbe-signature {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .chat-prompt__sig {
    position: relative;
    width: 100%;
    height: 7rem;
    object-fit: contain;
    z-index: 1;
    object-position: 0 50%;
    max-width: 1470px;
    margin: 0 auto;
  }
  .chat_prompt__welcome__title {
    position: relative;
    z-index: 1;
    color: #fff;
    white-space: pre-line;
    max-width: 1470px;
    margin: 0 auto;
    width: 100%;
    font-size: 0.835rem;
  }
}
.chat-prompt__input-box {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 100;
  max-width: 1280px;
  display: flex;
  gap: 1.75rem;
  width: calc(100% - 130px);
  padding: 0 65px calc(2rem + 55px);
  textarea {
    resize: none;
    border: none;
    outline: none;
    background: none;
    height: 60px;
    font-size: 1rem;
    width: 100%;
    padding: 0 1.5rem;
    line-height: 1.4em;
    height: 1.4em;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background-color: #fff;
  }
  .btn-wrapper{
    display: flex;
    align-items: center;
  }
  .chat-prompt__reset-btn {
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    img {
      filter: brightness(100);
      width: 1.6rem;
      display: block;
      margin-top:0.15rem;
    }
  }
  .voice-wrapper {
    margin-right: 25px;
    img {
      height: 1.8rem;
    }
  }
    .file-upload {
      cursor: pointer;
      & > label {

        position: relative;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        background: #0760AC;
      }
      & > * {
        cursor: pointer;
      }
    }
  .link-wrapper {
    position: absolute;
    left: 100%;
    margin-left: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #9cc93b;
  }
  .input_wrapper {
    width: calc(100% - 180px);
    height: 60px;
    border-radius: 30px;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(90deg, #00b9f2 0, #9cc93b);
    background-origin: border-box;
    border: 2px solid #0000;
    align-self: center;
    background-clip: content-box, border-box;
    position: relative;
    display: flex;
    align-items: center;
    .query__submit-label {
      padding:0 20px;
    }
    
  }
}
.ai-inform-txt {
  font-size: 0.65rem;
  text-align: center;
  color: #aeaeae;
  position: absolute;
  bottom: calc(2rem + 30px);
  left: 0;
  right: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 65px;
}
.chat-prompt__helpers {
  max-width: 1100px;
  width: calc(100% - 130px);
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  .helpers-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.9rem;
  }
  .guide-wrapper > a {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    .guide-img {
      width: 1rem;
      height: 1rem;
    }
  }
  .helper-list-icon {
    position: relative;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .helpers__history-bubble {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 2px 4px 12px -1px #d9d9d9;
    line-height: 110%;
    max-height: 300px;
    overflow-y: auto;
    padding: 1rem;
    position: absolute;
    top: -50%;
    transform: translate(-50%, -100%);
    width: 11rem;
    z-index: 1;
    color: #898989;
    a {
      color: #898989;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      margin-left: 1rem;
    }
    .bubble__title {
      font-size: 0.85rem;
      font-weight: 700;
      display: block;
      padding-bottom: 0.5rem;
      color: #000;
    }
  }
  .flex-container.footer-label {
    white-space: nowrap;
    color: #666;
    padding-left: 0.5rem;
    display: flex;
    gap: 0.4rem;
    p + p {
      color: #2db400;
    }
  }
}
.chat-prompt__box__converstion {
  padding: 0 65px;
  list-style: none;
  margin: 0;
  .your-question__box {
    max-width: 1470px;
    padding: 1.6rem 65px;
    font-size: 1rem;
    margin: 0 auto;
    font-weight: 700;
  }
}
.download-answer {
  display: flex;
  justify-content: flex-end;
  padding-right: 85px;
  padding-top: 10px;
  button {
    background-color: #0760ac;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    color: #fff;
    border: none;
    outline: none;
    font-size: 1rem;
  }
}
.user-guide-wrapper  {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  overflow: auto;
  z-index: 100;
  img {
    max-width: 100%;
  }
}
.chat-prompt__box .category {
  padding-bottom:180px;
  > div {width:auto;margin:0 20px;max-width:1200px;padding:0 65px;margin:0 auto;}
  .slick-slider {
    height: auto;
  }
}
.query__stop-btn {
  position: absolute;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
 gap:0.25rem;
  border: 2px solid #666;
  color: #666;
  position: absolute;
  bottom:100%;
  left: calc(50% - 2.5rem);
  font-weight: 700;
  img {
    line-height: 2rem;
    margin: auto;
    width: 2rem;
  }
  p:first-child {
    color: rgba($color: $cla-color-yellow, $alpha: 0.6);
  }
}
.clario-answer__box .user-reaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  > * + * {
    margin-top: 0;
  }
  a {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    img {
      width: 1rem;
    }
  }
  .user-comment {
    flex: 1;
    margin-left: 1rem;
    background-color: #fff;
    border-radius: 1.2rem;
    position: relative;
    input {
      border: none;
      outline: none;
      background: none;
      width: calc(100% - 30px);
      font-size: 0.9rem;
      padding: 0.7rem 1rem;
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 1rem;
      background-color: transparent;
      border: none;
      outline: none;
      height: 100%;
      img {
        height: 1.4rem;
      }
    }
  }
}
.chat-prompt__box__converstion {
  .template__list-row {
    background-color: #fff;
    padding: 1rem 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.2rem;
    margin-top: 1rem;
    + .template__list-row {
      margin-top: 0.45rem;
    }
    .flex-container {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      font-weight: 700;
    }
    .template__list-key {
      font-weight: 400;
    }
    .template__list-link {
      color: #fff;
      text-decoration: none;
      border-radius: 0.2rem;
      background-color: #27b7eb;
      padding: 0.5rem 0.7rem;
    }
  }
  .clario-answer__box, .error-wrapper {
    background-color: rgb(39 183 235 / 10%);
    border-radius: 1.2rem;
    padding: 1.75rem 85px;
    padding-bottom: 1.75rem !important;
    position: relative;
    font-size: 1rem;
    .clario-profile-wrapper, > img {
      top: 1.8rem;
      left: 34px;
    }
    .chat-prompt__parah.answer {
      width: 100%;
      > div > * + * {
        margin-top: 1.4rem;
      }
      > .user-reaction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        > * + * {
          margin-top: 0;
        }
        .user-comment {
          flex: 1;
          margin-left: 1rem;
          background-color: #fff;
          border-radius: 1.2rem;
          position: relative;
          padding-right: 4rem;
          input {
            border: none;
            outline: none;
            background: none;
            width: calc(100% - 30px);
            font-size: 0.9rem;
            padding: 0.7rem 1.2rem;
          }
          button {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 1rem;
            background-color: transparent;
            border: none;
            outline: none;
            height: 100%;
          }
        }
      }
      * {
        line-height: 1.6em;
      }
      code {
        display: block;
        white-space: pre-line;
      }
    }
    ul,
    ol {
      background-color: #fff;
      padding: 1.4rem 3.2rem;
      font-size: 1rem;
      font-weight: 400;
      list-style: none;
      border-radius: 1.2rem;
    }
    .convert-answer {
      ul,ol {
        background-color: transparent;
        padding:0 0 0 1.4rem;
      }
      p+ul, p+ol, p+p, ul+p, ol+p {
        margin-top:1rem;
      }
      ol+ul, ul+ol {
        margin-top:0.5rem;
      }
      ol, ol li {
        list-style-type: decimal;
        &:before {
          display: none;
        }
      }
    }
    li {
      position: relative;
    }
    .clario-answer__ref {
      padding-left: 2rem;
      margin-top: 1rem;
      li::before {
        display: none;
      }
      a {
        display: block;
        text-decoration: none;
        position: relative;
        color: #6f6f6f;
        &::before {
          content: "출처: ";
        }
      }
    }
    .clario-answer__rec {
      padding-left: 2rem;
      margin-top: 1rem;
      li::before {
        display: none;
      }
      img {
        height:1rem;
        vertical-align: middle;
        margin-top:-3px;
        margin-left:0.25rem;
        cursor: pointer;
      }
    }
    li::before {
      content: "";
      position: absolute;
      top: 0.7rem;
      left: -1rem;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background-color: #000;
    }
  }
  max-width: 1600px;
  margin: 0 auto;
}
@media screen and (min-width: 1024.01px) {
  .link-wrapper,
  .chat-prompt__reset-btn,
  .file-label {
    width: 46px;
    height: 46px;
    img {
      width: 22px;
      height: 24px;
    }
  }
  .file-label {
    margin-left: 10px;
  }
  .chat-prompt__box__title {
    padding: 2rem 85px;
  }
  .chat-prompt__box__converstion {
    padding-right:20px;
    padding-left:20px;
  }
}

@media screen and (max-width: 1024px) {
  .link-wrapper,
  .chat-prompt__reset-btn,
  .file-label {
    width: 46px;
    height: 46px;
    img {
      width: 22px;
      height: 24px;
    }
  }
  .file-label {
    margin-left:5px;
  }
  .chat-prompt__box__title {
    padding: 40px 20px;
  }
  .chat-prompt__box__converstion {
    padding: 0 20px;

    .template__list-row {
      flex-direction: column;
      gap:10px;
      align-items: flex-start;
      padding: 1rem;
  }
    .your-question__box {
      padding: 10px 0;
    }
    .clario-answer__box {
      padding: 1.75rem 20px;
      .clario-profile-wrapper {
        position: static;
        padding-bottom: 10px;
      }
      .chat-prompt__parah.answer > div > * + * {
        margin-top: 0.7rem;
      }
    }
  }
  .download-answer {
    padding-right: 20px;
  }
  .chat-prompt__input-box {
    width: calc(100% - 40px);
    gap: 10px;
   
    .ai-inform-txt {
      top: 55px;
    }
    .voice-wrapper {
      margin-right: 20px;
    }
    .chat-prompt__reset-btn img {
      width: 20px;
    }
    .link-wrapper {
      margin-left: 10px;
      img {
        height: 26px;
      }
    }
    .input_wrapper {
      width: calc(100% - 130px);
      height: 46px;
      .query__submit-label {
        padding:0 10px;
        img {
          width: 20px;
        }
      }
    }
  }
  .chat-prompt__box__converstion .clario-answer__box .chat-prompt__parah.answer > .user-reaction {
    display: block;
    .user-comment {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }
  .chat-prompt__box .category  {
    > div {padding:0 20px;}
  }
  .link-wrapper,
  .chat-prompt__reset-btn,
  .file-label {
    width: 46px;
    height: 46px;
  }
  .file-labe {
    margin-left: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width:1023.99px) {
  .link-wrapper,
  .chat-prompt__reset-btn,
  .file-label {
    width: 40px;
    height: 40px;
     img {
      width: 20px;
      height: 22px;
    }
  }
  .file-label {
    margin-left: 5px;
  }
}
@media screen and (max-width: 767.99px) {
  .link-wrapper,
  .chat-prompt__reset-btn,
  .file-label {
    width: 35px;
    height: 35px;
    img {
      width: 18px;
      height: 20px;
    }
  }
  .file-label {
    margin-left: 5px;
  }
}
::-webkit-scrollbar {
  width: 6px; /* 스크롤바의 너비 */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 스크롤바 트랙 배경색 */
}

::-webkit-scrollbar-thumb {
  background-color: #C5C5C5; /* 스크롤바 핸들 색상 */
  border-radius: 6px; /* 스크롤바 핸들의 모서리 반경 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.welcome__box{
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
  vertical-align: middle;
  text-align: center;
  background-image: url('../assets/images/splish.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: fadeIn 1s ease-in-out;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
     
      align-self: center;
    }
    img:first-child {
      width: 16rem;
    }
    img:nth-child(2) {
      width: 23rem;
    }
    img:nth-child(3) {
      width: 68rem;
      padding: 1rem 0;
    }
    img:last-child {
      padding: 1rem 0;
      z-index: 1;
      width: 17rem;
    }
  }
  & > img {
    position: absolute;
    width: 65%;
    filter: saturate(3);
  }
  #blossom-left {
    left: 0;
    top: 0;
  }
  #blossom-right {
    right: 0;
    bottom: 0;
  }
}

.welcome__box.open {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

@keyframes rightOpen {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes leftOpen {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
.loading-wrapper {
		display: flex;
		align-items: center; 
		display: flex; 
		justify-content: center; 
		height: 100vh;
		width: 100%;
		img {
			width: 10rem;
			margin: auto;
		}
	}
  .modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    font-size: 0.8rem;
    color: $gbe-font-color;
    .modal-content {
      background-color: #ffffff;
      width: 300px;
      height: 150px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 0.5rem;
      .alert__top,
      .alert__middle {
        text-align: left;
      }
      .alert__top{
        font-size: 1rem;
        font-weight: 700;
      }
      .alert__bottom {
        text-align: right;
        button {
          padding: 0.5rem 1.2rem;
          border-radius: 0.5rem;
          border: none;
        }
        button:first-child {
          margin-right: 1rem;
        }
      }
    }
    .warning-content {
      background-color: #ffffff;
      min-width: 200px;
      max-width: 400px;
      width: auto;
      height: 50px;
      transition: all ease 1s;
      padding: 15px;
      border-radius: 0.5rem;
      .warning__top {
        margin: 0.5rem 0;
        font-weight: 700;
      }
      .warning__middle {
        margin: 0.5rem 0;
      }
    }
    .search__content{
      width: 450px;
      min-height: 200px;
      height: auto;
      background: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 0.5rem;
      .search__top,
      .search__middle {
        text-align: left;
        .search__form {
          display: flex;
          margin: 2rem 0;
          input {
            border: none;
            border-bottom: 1px solid $gbe-border-color;
            flex: 1;
            padding: 0.5rem;
            font-size: 1rem;
          }
          button {
            margin-left: 1rem;
            width: 4rem;
            border: 1px solid $gbe-color-blue;
            background: none;
            border-radius: 0.5rem;
            color: $gbe-color-blue;
            font-weight: 700;
          }
        }
      }
      .search__bottom {
        text-align: right;
        button {
          padding: 0.5rem 1.2rem;
          border-radius: 0.5rem;
          color: white;
          background: $gbe-color-blue;
          border: none;
        }
        button:first-child {
          margin-right: 1rem;
        }
      }
    }
  }