.play__box {
  background-color: $gbe-color-sky;
  border-radius: 2rem;
  display: flex;
  position: relative;
  & > .clario-profile-wrapper {
    position: absolute;
    padding: 1rem;
    top: 17px;
    left: 10px;
  }
  & > .flex-container {
    padding: 1rem;
  }
  .play__box-list {
    padding: 1rem;
    margin-left: 2rem;
    position: relative;
   
    .play__box-inform {
      padding: 1rem;
    }
    .play__list-item {
      // background: white;
      padding: 1rem 0;
      margin: 1rem 0;
      // border-radius: 1rem;
      position: relative;
      .play__clipboard-btn {
        position: absolute;
        top: 1rem;
        right: 0;
        padding: 1rem;
        cursor: pointer;
      }
      .play__list-row {
        display: flex;
        padding: 1rem;
        .play__list-key {
          margin-right: 1rem;
          font-weight: 700;
          min-width: 6rem;
          
        }
       
        .play__link-value {
          text-decoration: underline;
          color: $gbe-color-blue;
        }
      }
    }
    .play__list-item:not(:last-child) {
      border-bottom: 1px solid $gbe-border-color;
    }
  }
}

.template__box {
  margin-left: 2rem;
  position: relative;
  .clario-profile-wrapper {
    position: absolute;
    padding: 1rem;
    top: 0px;
    left: -30px;
  }
  .template__box-inform {
    padding: 1rem;
  }
  .template__list-item {
    position: relative;
    padding: 1rem 0;
   .template__clipboard-btn {
    position: absolute;
    top: 1rem;
    right: 0;
    padding: 1rem;
    cursor: pointer;
   }
    .template__list-row {
      display: flex;
      padding: 1rem;
      margin-right: 2rem;
      .template__list-key {
        font-weight: 700;
      }
      .template__list-value {
        white-space: pre-line;
        word-break: break-word;
        .template__list-link {
          text-decoration: underline;
        }
      }
      .template__list-link {
        text-decoration: underline;
        color: $gbe-color-blue;
      }
    }
  }
  .template__list-item:not(:last-child) {
    border-bottom: 1px solid $gbe-border-color;
    margin-bottom: 1rem;
  }
  
}
@media screen and (min-width: 1024px) {
  .play__box-list {
    margin-bottom: 5rem;
    .play__list-row {
      
    }
  }
  .template__box {
    .template__list-key {
      margin-right: 1rem;
      min-width: 6rem;
    }
  }
}

@media screen and (min-width: 768.99px) and (max-width: 1023.99px) {
  .play__box {
    .play__box-list {
      margin-bottom: 5rem;
      .play__list-row {
        
      }
      .play__list-item {
        .play__list-row,
        .play__box-inform {
          font-size: 13px;
        }
      }
    }
  }
  .template__box {
    .template__list-key {
      margin-right: 1rem;
      min-width: 6rem;
    }
  }
}

@media screen and (max-width: 768.98px) {
  .play__box-list {
    margin-bottom: 5rem;
    .play__list-row {
      
    }
  }
  .template__box {
    .template__list-key {
      min-width: 2rem;
      margin-right: 5px;
    }
  }
}
