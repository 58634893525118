// color
$gbe-color-green : #9cc93b;
$gbe-color-blue : #0079c3;
$gbe-color-lightblue: #00B9F2;
$gbe-color-yellow : #f6ac1d;
$gbe-color-border : #e5e5e5;
$gbe-color-focus : #666;
$gbe-color-sky : #f4f9fc;
$gbe-border-color: #e5e5e5;
$gbe-font-color: #3C3C3B;
$gbe-placeholder-color: #666;

$cla-color-yellow : #F39200;
$cla-color-green : #3aab35;
$cla-color-skyblue : #36A9E1;
$cla-color-blue : #1D71B8;
$cla-color-black : #3C3C3B;

$nav-color : #0079c3;
$naver-color: #2db400;
// other ops
$gbe-border-radius : 8px;




