.play-prompt {
  margin-left: -0;
  background-color: reds;
  .sample-questions {
    .sample-wrapper {
      .sample-list {
        cursor: pointer;
        border-radius: 1rem;
        background-color: rgba($color: $gbe-color-blue, $alpha: 0.05);
        img {
          padding: 0.5rem;
          align-self: center;

        }
        p {
          text-align: left;
       }
       .sample-category {
        color: $gbe-color-blue;
        font-weight: 700;
        // padding-left: 0.5rem;
        line-height: 2rem;
       }
    }
    .sample-list:hover {
      transition: all ease 0.4s;
      border-radius: 1rem;
      background-color: rgba($color: $gbe-color-blue, $alpha: 0.15);
    }
    }
      
  }
  .play-prompt__box {
    height: 100vh;
    position: relative;
    background-color: white;
    .play-prompt__box__title {
      // background-image: url('../../assets/gif/banner.gif');
      background-repeat: no-repeat; 
      position: relative;
      // background-size: cover;
      // background-repeat: no-repeat;
      // border-bottom: 1px solid $gbe-border-color;
      // position: relative;
      
    }
    .play-prompt__box__history {
      min-width: 300px;
      width: 100vw;
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      height: calc(100% - 12rem);
      .play-prompt__sig {
        position: absolute;
        top: 20%;
        padding-left: 10%;
        max-width: 350px;
      }
      .chat_prompt__welcome__title {
        display: block;
        white-space: pre-line;
        color: $gbe-font-color;
        font-family: 'NotoR';
        position: absolute;
        color: white;
        letter-spacing: 1px;
        padding-left: 10%;
      }
      // .play-prompt__box__converstion {
      //   padding-bottom: 12rem;
      // } 
      & > .play-prompt__box__title {
        // padding: 6rem 0;
        & > .gbe-signature {
          width: 100vw;
          max-height: 300px;
        }
      }
      ul {
        .chat-prompt__conversation-list {
          // padding: 1rem 0;
          // border-top: 1px solid #d8d8d8;
          // border-bottom: 1px solid #d8d8d8;
          .naver-search{
            background-color: $naver-color;
            color: white;
            border-radius: 5px;
            border: none;
            margin-top: 1rem;
            padding: 0.5rem;
          }
          .user-reaction {
            // position: absolute;
            // top: 6px;
            // right: 0;
            bottom: -4rem;
            left: -0.5rem;
            line-height: 2rem;
            display: flex;
            width: 100%;
         
            a {
              padding:1rem;
              border-radius: 1rem;
            }
            // .btn-img {
            //   padding:1rem;
            //   border-radius: 1rem;
            // }
            .btn-img:hover {
              // padding:1rem;
              background-color: rgba($color: #ffffff, $alpha: 0.6);
            }
            .user-comment {
              margin: 12px 0;
              flex: 1;
              background: white;
              border-radius: 2rem;
              input {
                flex: 1;
                width: 320px;
                border: none;
                padding: 5px 10px;
                overflow-wrap: normal;
                border-radius: 2rem;
                font-size: 12px;
              }
              button {
                border: none;
                padding: 5px;
                background-color: transparent;
                margin-right: 10px;
                img {
                  width: 1.2rem;
                }
              }
            }
          }

           .clario-answer__box {
            padding: 1rem;
            height: fit-content;
            position: relative;
            & > .flex-container {
              position: relative;
              
             
            }
            & > .clario-profile-wrapper,
            & > .clario-user-wrapper {
              width: 1rem;
              height: 1rem;
              padding: 1rem;
            }
            
             .chat-prompt__parah {
              margin-top: auto;
              line-height: 2rem;
              justify-content: space-between;
              position: relative;
              min-height: 1rem;
              color: $gbe-font-color; 
               .rec__preview {
                ul, li {
                  list-style: none;
                  background-color: white;
                }
                .map-wrapper {
                  & > div:first-child {
                    border-radius: 2rem;
                 
                    .marker-box {
                        display: flex;
                        padding: 5px;
                        background:#0475f4;
                        border-radius: 2rem;
                        box-shadow: 2px 4px 12px -1px #d9d9d9;
                        color: white;
                        font-size: 0.8rem;
                        max-width: 145px;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        min-width: 50px;
                        border: none;
                        cursor: pointer;
                        .marker-icon {
                          width: 1rem;
                          height: 1rem;
                          background: white;
                          padding: 5px;
                          border-radius: 50%;
                          margin: auto;
                        }
                        .map-icon__top {
                          z-index: 1;
                        }
                        .marker-name {
                          font-weight: 700;
                          margin: auto;
                          padding: 0 5px;
                          font-size: 83%;
                          min-width: 50px;
                        }
                    }
                    .marker-box:after {
                      border-top: 10px solid #0475f4;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 0px solid transparent;
                      content: "";
                      position: absolute;
                      top: 36px;
                      left: 23px;
                  }
                  }
                }
                .rec__preview__list {
                  margin-right: 5px;
                  text-align: center;
                  display: flex !important;
                  min-height: 150px;
                  height: fit-content;
                  margin: 1rem 0;
                  border-radius: 1rem;
                  background-color: white;
        
                  img {
                    transition: all ease 1s;
                    cursor: pointer;
                    display: block;
                    border-radius: 1rem;
                    margin: auto 1rem;
                    flex: 1;
                  }
                 
                  .label-txt {
                    font-weight: 700;
                    font-size: 1rem;
                    
                  }
                  p {
                    font-size: 0.8rem;
                  }
                  .rec__preview__summary {
                     margin: 0 10px;
                     padding: 1rem;
                     flex: 1;
                  }
                  .rec__preview__detail{
                    max-height: 200px;
                    overflow: hidden;
                    padding: 1rem;
                    flex: 1;
                    .rec__preview__sub{
                      border-radius: 10px;
                      background-color: rgba($color: $gbe-color-yellow, $alpha: 0.1);
                      padding: 5px 10px;
                      margin-top: 5px;
                      font-weight: 700;
                      width: fit-content;
                      color: $gbe-color-yellow;
                    }
                    .rec__preview__desc{
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }
               
               }
               .naver__preview {
                .slide-wrapper {
                  transition: all ease 0.2s;
                  width: inherit;
                  border: 2px solid $naver-color;
                  border-radius: 1rem;
                  max-height: 250px;
                  height: 250px;
                  overflow: hidden;
                  color: $naver-color;
                  background-color: white;
                  .nv-cd__title,
                  .nv-cd__desc {
                    padding: 10px;
                  }
                  .nv-cd__title {
                    font-size: 1rem;
                    font-weight: 700;
                  }
                  .nv-cd__desc {
                    font-size: 0.8rem;
                  }
                }
                .slide-wrapper:hover {
                  background-color: rgba($color: $naver-color, $alpha: 0.5);
                  border: 2px solid rgba($color: $naver-color, $alpha: 0);
                  color: white;
                }
                ul, li {
                  list-style: none;
                }
             
               }
               p {
                overflow-wrap: break-word;
                white-space: pre-wrap;
               }
               table {
                th, td {
                  padding: 10px;
                  border: 1px solid $gbe-color-border;
                }
                
              }
              
              ol, ul {
                list-style: initial;
                border-radius: 1rem;
                margin: 1rem 0;
                background-color: white;
                padding: 2rem;
                li:not(:last-child) {
                  padding-bottom: 0.5rem;
                }
              
              }
              .naver__preview{
                background-color: transparent;
                margin: 0;
                padding: 0;
              }
              & div > ol,
              & div > ul {
                background-color: #fff;
              }
              strong {
                font: revert !important;
                
              }
              h1, h2, h3, h4, h5, h6 {
                font-style: initial;
              }
            }
            & > span {
              display: block;
              align-self: center;
              padding-left: 1rem;
              line-height: 2rem;
            }
            & > span:last-child {
              // padding: 0;
            }
            .program-wrapper {
              margin-top: auto;
              line-height: 2rem;
              justify-content: space-between;
              position: relative;
              min-height: 1rem;
              color: #3C3C3B;
             .contents {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
             }
              .title {
                margin-bottom: 10px;
                font-weight: 700;
                
              }

             li {
              background-color: white;
              margin-bottom: 10px;
              padding: 5px 10px;
              border-radius: 5px;
              justify-content: space-between;
              .download {
                padding: 0 1rem;
                img {
                  width: 1.2rem;
                }
              }
              button {
                border: none;
                color: white;
                border-radius: 5px;
                padding: 5px;
                background-color: $gbe-color-blue;
               }
             }
             }
          }
          & > div:first-child {
            .chat-prompt__parah {
              font-weight: 600;
              // margin-left: 1rem;
              line-height: 1.2rem;
            }
          }
          & > .clario-answer__box {
            background-color: rgba(39, 183, 235, 0.1);
            border-radius: 2rem;
            .clario-answer__ref {
              padding: 1rem;
              display: flex;
              border-radius: 2rem;
              background-color: white;
              flex-wrap: wrap;
              line-height: 1.2rem;
              li {
                margin-right: 5px;
                a {
                  color:#267ffb;
                }
              }
            }
            .clario-answer__rec {
              padding: 1rem;
              border-radius: 2rem;
              background-color: white;
              margin-top: 1rem;
              li {
                margin-bottom: 1rem;
                 p:last-child {
                  display: flex;
                  img {
                    align-self: center;
                    margin-left: 0.4rem;
                    display: block;
                    cursor: pointer;
                    width: 1rem;
                  }
                }
              }
              li:first-child {
                p:last-child {
                  padding-top: 1rem;
                }
              }
            }
          }
          .error-wrapper {
            display: flex;
            border-radius: 2rem;
            padding: 1rem 2rem;
            position: relative;
            background: #FDEDEC;
            color: darkred;
            img {
              position: absolute;
              width: 1rem;
            }
            p {
              margin-left: 2rem;
            }
          }
        }
      }
    }
    .play-prompt__input-box {
      // test
      // position: relative;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      height: 12rem;
      left: 0;
      background-color: white;
      min-width: 280px;
      z-index: 1;
      .input_wrapper {
        border: 2px solid transparent;
        padding: 1px;
        min-height: 17px;
        max-height: 300px;
        box-sizing: border-box;
        resize: none;
        height: 3rem;
        align-self: center;
        flex: 1;
        border-radius: 3.75rem;
        position: relative;
        background-image: linear-gradient(#fff, #fff), linear-gradient(to right,$gbe-color-lightblue 0%, $gbe-color-green 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        .sending-btn {
          position: absolute;
          padding: 0.8rem;
          top: 0;
          right: 0.5rem;
          width:  1.2rem;
          cursor: pointer;
        }
    }
    .input_wrapper {
      .link-wrapper {
        cursor: pointer;
        background: $gbe-color-green;
        padding: 6px;
        border-radius: 50%;
        text-align: center;
       
        position: absolute;
      }
      .link-wrapper:hover {
        background-color: rgba($color: $gbe-color-green, $alpha: 0.4); 
      }
    }
      textarea {
        border: none;
        resize: none;
        width: calc(100% - 4rem);
        height: 1.5rem;
        font-family: 'NotoR';
        line-height: 1.5rem;
        padding: 9px;
        border-radius: 3.75rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .chat-prompt__reset-btn {
        position: absolute;
        border-radius: 50%;
        padding: 5px;
       
        button {
          display: none;
        }
        label {
          img {
           
            filter: brightness(100);
          }
        }
      }
      .ai-inform-txt {
        position: absolute;
        bottom: 4.8rem;
        text-overflow: ellipsis;
        white-space: nowrap;     /* 줄 바꿈 방지 */
        overflow: hidden;
        left: 0;
        color: rgba(174, 174, 174, 1);
        width: calc(100% - 6rem);
        
      }
      .play-prompt__reset-btn .hover {
        background-color: rgba($color: $gbe-color-lightblue, $alpha: 0.4); 
      }
      .query__submit-label {
        display: flex;
        align-items: center;
        img {
          // width: 1.3rem;
        }
      }
      .query__stop-btn {
        position: absolute;
        border-radius: 5px;
        padding: 5px;
        background-color: white;
        cursor: pointer;
        
       
        border: 2px solid #666;
        color: #666;
        
        left: calc(50% - 2.5rem);
        font-weight: 700;
        img {
          line-height: 2rem;
          margin: auto;
          width: 2rem;
        }
        p:first-child {
          color: rgba($color: $cla-color-yellow, $alpha: 0.6);
          padding-right: 0.5rem;
        }
      }
    
      textarea:focus {
        outline: none;
      }
      
      .query__submit-label {
        cursor: pointer;
        justify-content: center;
        min-height: 2.7rem;
        align-self: center;
        font-size: 1rem;
        max-width: 70px;
        min-width: 50px;
      }
      & > .play-prompt__input-value,
      & > .play-prompt__submit-btn
      {
        // box-shadow: 3px 2px 7px $gbe-border-color;
        border-radius: 1rem;
        color: $gbe-placeholder-color;
        font-family: 'NotoR';
      }
      & > .play-prompt__input-value {
        margin-right: 1rem;
      }
      & > .play-prompt__input-value::-webkit-scrollbar {
        width: 6px; /* 스크롤바의 너비 */
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
      }
      
      & > .play-prompt__input-value::-webkit-scrollbar-track {
        background-color: #f1f1f1; /* 스크롤바 트랙 배경색 */
      }
      
      & > .play-prompt__input-value:-webkit-scrollbar-thumb {
        background-color: #C5C5C5; /* 스크롤바 핸들 색상 */
        border-radius: 2rem; /* 스크롤바 핸들의 모서리 반경 */
      }
      
      & > .play-prompt__input-value::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }

      & > .chat-prompt__helpers {
        position: absolute;
        padding-bottom: 1rem;
        left: 0;
        bottom: 0;
        width: 100%;
        .helpers-list {
          display: flex; 
          align-items: center;
         
          .helper-list-icon {
            cursor: pointer; position: relative;
            margin-right: 1rem;
            // width: 40px;
            // height: 40px;
            border-radius: 1rem;
            img {
              width: 100%;
            }
          .helpers__history-bubble {
            position: absolute;
            background: white;
            z-index: 1;
            padding: 1rem;
            width: 11rem;
            top: -50%;
            transform: translate(-50%, -100%);
            border-radius: 1rem;
            line-height: 110%;
            overflow-y: auto;
            max-height: 300px;
            box-shadow: 2px 4px 12px -1px #d9d9d9;
            .bubble__title {
              font-size: 1rem;
              font-weight: 600;
              text-align: left;
              display: block;
              margin-bottom: 7px;
            }
            .history-bubble_item,
            .history-bubble_list > p {
              border-bottom: 1px solid 898989;
              // padding: 5px 0;
              // margin-bottom: 5px;
              font-size: 0.8rem;
              color: #898989;
              text-align: left;
            }
            .list-style {
              list-style: circle;
              margin-left: 18px;
            }
            .bubble_item_title {
              display: block;

            }
          }
          }
         
          .helper-blue:hover {
             box-shadow: 2px 4px 12px -1px #d9d9d9;
          }
          .helper-yellow:hover {
             box-shadow: 2px 4px 12px -1px #d9d9d9;
          }
          .helper-grey:hover {
             box-shadow: 2px 4px 12px -1px #d9d9d9;
          }
          .helper-green:hover {
             box-shadow: 2px 4px 12px -1px #d9d9d9;
          }
          .footer-label {
            
            font-weight: 700;
            letter-spacing: 0.5px;
            p:first-child {
              color: $gbe-placeholder-color;
            }
            p:last-child {
              color: $naver-color; 
              padding: 0 0.5rem;
            }
          }
        }
        .guide-wrapper {
          position: absolute;
          display: flex;
          border-radius: 50%;
          width: 44px;
          height: 44px;
          background: rgba(244, 244, 244, 1);
          margin-left: auto;
          justify-content: center;
    line-height: 44px;
          .guide-img {
            width: 1rem;
            height: 1rem;
            margin: auto;
          }
        }
      }
     
    }
  }

}
.curtain {
  position: relative;
  width: 100%;
  height: 100vh;
  flex: 1;
}

.curtain__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.searched_item {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 2rem;
}
.curtain__panel {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100vh;
  background: white;
  z-index: 2;
  transition: all 0.5s ease-out;
}
.left {
  left:0;
}
.right {
  left:50%;
}
.curtain__panel--left {
  transform: translateX(-100%);
  animation: leftOpen 2s linear;
}

.curtain__panel--right {
  transform: translateX(100%);
  animation: rightOpen 2s linear;
}

.welcome__box{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  vertical-align: middle;
  text-align: center;
  background-image: url('../../assets/images/splish.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: fadeIn 1s ease-in-out;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
     
      align-self: center;
    }
    img:first-child {
      width: 16rem;
    }
    img:nth-child(2) {
      width: 23rem;
    }
    img:nth-child(3) {
      width: 68rem;
      padding: 1rem 0;
    }
    img:last-child {
      padding: 1rem 0;
      z-index: 1;
      width: 17rem;
    }
  }
  & > img {
    position: absolute;
    width: 65%;
    filter: saturate(3);
  }
  #blossom-left {
    left: 0;
    top: 0;
  }
  #blossom-right {
    right: 0;
    bottom: 0;
  }
}

.welcome__box.open {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

@keyframes rightOpen {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes leftOpen {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.flex-container {
  display: flex;
}
/* 추가된 스타일 */
.play-prompt.sidebar-open {
  margin-left: 0; /* SideBar의 폭과 동일하게 조절 */
  // transition: margin-left 0.5s ease;
}

.clario-profile-wrapper {
  width: 1rem;
    height: 1rem;
    padding: 0 10px 0 0;
  img {
    width: inherit;
    height: inherit;
  }
}
.fetching-btn {
  position: relative;
  width: 50px;
  height: 50px;
  img {
    position: absolute;
    transition: all ease 1s;
  }
  img:first-child {
    top: 13px;
    left: 13px;
  }
  img:nth-child(2) {
    left: 13px;
    top: 15px;
  }
  img:last-child {
    top: 13px;
    left: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .play-prompt__box__history {
    .clario-answer__box {
      & > .flex-container {
        width: calc(100% - 3rem);
        .clario-profile-wrapper{
          position: absolute;
          top: -2px;
          padding: 0.7rem 1rem 1rem 1rem;
        }
        .answer {
          margin-left: 3rem;
          width: 100%;
        }
      }
    }
    .play-prompt__box__title {
      // padding:5rem 10rem 2.5rem 10rem;
      .gbe-signature {
        min-width: 280px;
        max-height: 320px;
      }
      .play-prompt__sig {
        width: 25%;
      }
    }
    .your-question__box {
        padding: 2rem 1rem;
    }
    .play-prompt__parah {
      font-size: 18px;
        margin-left: 1rem;
      .rec__preview__list {
        justify-content: space-evenly;
        & > div {
          flex: 1;
        }
        div {
          text-align: left;
        }
        // .rec__preview__summary {
        //   width: 300px;
        // }
        // .rec__preview__detail {
        //   width: 34%;
        // }
        .rec__preview__desc {
          // height: 200px;
        }
        img {
          width: 200px;
          height: 200px;
          min-width: 200px;
          min-height: 200px;
          margin: auto;
        }
      }
     }
    .slick-list {
      font-size: 18px;
    }
    .play-prompt__box__converstion,
    .sample-questions {
      margin: 2% 17% 0%;
      .sample-wrapper {
        margin: 0 9%;
        min-width: 30%;
        .sample-list {
          margin: 0 5px 5px 0;
          min-width: 160px;
          cursor: pointer;
          padding: 1rem;
          img {
            width: 1.4rem;
            height: 1.4rem;
            align-self: center;
          }
          .sample-category {
            font-size: 0.8rem;
            padding: 0.5rem;
          }
          .sample-query {
            font-size: 1rem; 
            font-weight: 700;
            padding: 0 0.5rem;
            line-height: 1.5rem;
         }
      }
 
      }
    }
    .chat_prompt__welcome__title {
        line-height: 120%;
        font-size: 100%;
        bottom: 15%;
    }
  
  }
  .play-prompt__input-box {
    margin: 0 6% 0 4%;
    width: 88%;
    .input_wrapper {
      margin: 1rem 1.5rem 5rem 4rem;
      .link-wrapper {
        padding: 6px;
        right: -68px;
        top: -1px;
        line-height: 32px;
        width: 32px;
        height: 32px;
         a {
          line-height: 2.5rem;
          img {
            width: 1.5rem;
          }
        }
      }
      .link-wrapper + a {
        line-height: 1.5rem;
      }
      .voice-wrapper {
        .voice-wrapper__icon {
          opacity: 1;
          transition: opacity 1s ease 0s;
          line-height: 42px;
          display: block;
          margin: auto;
          height: 30px;
          margin: 5px 0;
        }
      }
    }
    .input_wrapper + a{
      width: 2rem;
      height: 2rem;
      right: -4%;
      top: 2.6rem;
      a {
        img {
          width: 1.52rem;
          padding: 6px;
        }
      }
    }

    .query__submit-label {
      width: 100px;
    }
    .helpers-list {
      margin-left: 4rem;
      .helper-list-icon {
        width: 40px;
        height: 40px;
      }
    }
    .chat-prompt__reset-btn {
      top: 2.5rem;
      label {
        img {
          width: 1.4rem; padding: 5px; 
        }
      }
    }
    
 .helpers__history-bubble {
            left: 290%;
 }

 .play-prompt__parah .answer {
  & > div {
    width: calc(100% - 3rem);
    margin-left: 3rem;
  }
 }
  }
  .query__stop-btn {
    top: -4rem;
    right: 50%;
    line-height: 2rem;
    height: 2rem;
    width: 5.5rem;
  }

  .ai-inform-txt {
    font-size: 0.7rem;
    margin-left: 4rem;
  }
  .footer-label {
    padding: 0 1rem;
  }
  .guide-wrapper {
    bottom: 1rem;
    right: -3rem;
 
  }
}
@media screen and (min-width: 768.99px) and (max-width: 1023.99px) {
	.play-prompt__box__history {
    // padding: 0 5rem;
    .play-prompt__box__title
     {
      .gbe-bg-img {
        right: 0%;
      }
      .gbe-signature {
        min-width: 280px;
        max-height: 320px;
      }
      .play-prompt__sig {
        width: 18.2%;
      }
    }
    .chat_prompt__welcome__title {
      line-height: 110%;
      font-size: 70%;
      bottom: 20%;
  }
    .play-prompt__box__converstion{
      padding: 0 5rem;
      .your-question__box {
        padding: 1rem;
    }
    .clario-answer__box {
      & > .flex-container {
        width: calc(100% - 3rem);
        .clario-profile-wrapper{
          position: absolute;
          top: -2px;
          padding: 0.7rem 1rem 1rem 1rem;
        }
        .answer {
          margin-left: 3rem;
          width: 100%;
        }
      }
      .answer {
        & > div {
          width: calc(100% - 3rem);
         
        }
       }
    }
    }
    .gbe-signature,
    .play-prompt__sig {
    } 
 
	}
  .template__box {
    .template__list-row,
    .template__box-inform {
      font-size: 13px;
    }
  }
  .play-prompt__input-box  {
    // margin: 0 5rem;
    margin-right: 0% 2%;
    width: 93%;
    .input_wrapper {
      margin: 1rem 2rem 5rem 4rem;
      .link-wrapper {
        right: -68px;
        width: 28px;
        height: 28px;
        top: 2px;
        line-height: 28px;
        a {
          line-height: 2.2rem;
          img {
            width: 1.3rem;
          }
        }
      }
    }
    .input_wrapper + a{
      width: 2rem;
      height: 2rem;
      right: -4%;
      top: 2.6rem;
      a {
        img {
          width: 1.2rem;
          padding: 7px;
        }
      }
    }
    .voice-wrapper {
      .voice-wrapper__icon {
        line-height: 35px;
        display: block;
        margin: auto;
        height: 29px;
        margin: 6px 0;
      }
    }
    .query__submit-label {
      width: 100px;
    }
    .helpers-list {
      margin-left: 4rem;
      .helper-list-icon {
        width: 40px;
        height: 40px;
      }
    }
 .helpers__history-bubble {
            left: 100%;
 }
 .chat-prompt__reset-btn {
  top: 41px;
  left: 0.9rem;
  label {
    img {
      width: 1.2rem; padding: 5px; 
    }
  }
  top: 2.5rem;
}
  }
  .play-prompt__parah {
    font-size: 0.8rem;
    
    .rec__preview__list {
      justify-content: space-between;
      div {
        text-align: left;
      }
      // .rec__preview__summary {
      //   width: 200px;
      // }
      // .rec__preview__detail {
      //   width: 34%;
      // }
      .rec__preview__desc {
        // height: 200px;
      }
      img {
        width: 200px;
        height: 200px;
        min-width: 200px;
        min-height: 200px;
        margin: auto;
      }
    }
  }
  .slick-list {
    font-size: 0.8rem;
  }
  .clario-answer__rec {
    li {
      font-size: 13px;
    }
  }
  .clario-answer__ref {
    li {
      font-size: 13px;
    }
  }
  .query__stop-btn {
    top: -1.5rem;
    line-height: 1.5rem;
    height: 1.5rem;
    width: 5rem;
    p {
      font-size: 13px;
    }
  }
  .sample-questions {
    .sample-wrapper {
      margin: 0 5%;
      .sample-list {
        margin: 1%;
        padding: 1rem;
        min-width: 30%;
        img {
          width: 1rem;
          height: 1rem;
        }
        .sample-category {
          font-size: 0.6rem;
        }
        .sample-query {
          font-size: 0.8rem; 
          font-weight: 600;
          padding: 0.5rem;
          line-height: 1rem;
       }
    }
    }
  }
  .empty-list {
    width: 16%;
  }
  .ai-inform-txt {
    font-size: 0.7rem;
    margin-left: 6rem;
  }
  .footer-label {
    padding: 0 1rem;
  }
  .guide-wrapper {
    bottom: 1rem;
    right: -2.5rem;
    width: 44px;
    height: 44px;
    img {
      width: 44px; 
      height: 44px;
    }
  }
}
@media screen and (max-width: 768.98px) {

	.play-prompt__box__history {
    // padding: 0 1rem;
   
    .play-prompt__box__title {
      .gbe-bg-img {
        right: 0%;
      }
      .gbe-signature {
        height: 130px;
      }
      .play-prompt__sig {
        width: 28%;
        min-width: 135px;
      }
    }
    .your-question__box {
      padding: 1rem;
      
  }
  .clario-answer__box {
    & > .flex-container {
      flex-direction: column;
      width: 100%;
      .clario-profile-wrapper {
        padding: 0.7rem 1rem 1rem 0;
      }
      .answer {
        margin-top: 3rem;
        width: 100%;
      }
    }
  }
    .sample-questions {
      .sample-wrapper {
        flex-direction: column;
        .sample-list {
          margin: 2%;
          padding: 0.5rem;
          flex: 1;
          img {
            width: 0.7rem;
            height: 0.7rem;
          }
          .sample-category {
            font-size: 0.5rem;
          }
          .sample-query {
            font-size: 0.7rem; font-weight: 700;
            text-align: left;
            padding: 0.5rem;
            line-height: 1rem;
         }
      }
      }
    }
    .gbe-signature {
    }
    .play-prompt__box__converstion {
      font-size: 0.7rem;
      padding: 1rem;
    }
    .chat_prompt__welcome__title {
      font-size: 51%;
      bottom: 20%;
      line-height: 110%;
      max-width: 80%;
    }
    .play-prompt__sig {
    }
    .sample-questions {
      padding: 0 1rem;
    }
    .play-prompt__parah {
      font-size: 0.8rem;
      
    }
    .slick-list {
      font-size: 0.8rem;
    }
    .clario-answer__rec {
      li {
        font-size: 13px;
      }
    }
    .clario-answer__ref {
      margin-top: 3rem;
      li {
        font-size: 13px;
      }
    }

    .user-reaction {
      flex-wrap: wrap;
      // bottom: -6rem !important;
      .user-comment {
        max-width: 94%;
        
      }
    }
	}
  .play-prompt__input-box  {
    margin: 0 1rem;
    width: calc(100vw - 2rem);
    .input_wrapper {
      margin: 1rem 3rem 5rem 3rem;
      label {
        img {
          width: 0.8rem;
          padding: 1rem;
        }
      }
    }
    .input_wrapper {
     
      .link-wrapper {
        top: 5px;
        right: -50px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        a {
          line-height: 1rem;
          img {
            width: 1rem;
          }
        }
      }
      .voice-wrapper {
        .voice-wrapper__icon {
          line-height: 30px;
          display: block;
          margin: auto;
          height: 26px;
          margin: 7px 0;
        }
      }
    }
    
    .query__submit-label {
      width: 50px;
    }
    .helpers-list {
      margin-left: 0;
      .helper-list-icon {
        width: 30px;
        height: 30px;
      }
    }
    
    .helpers__history-bubble {
                left: 264%;
    }
    .chat-prompt__reset-btn {
      width: 1.5rem;
      height: 1.5rem;
      top: 2.8rem;
      label {
        img {
          width: 0.8rem; padding: 5px; 
        }
      }
    }
}
.rec__preview__list {
  div {
    text-align: center;
  }
  flex-direction: column;
  .rec__preview__sub {
    margin: auto;
  }
  img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    align-self: center;
  }
}
.query__stop-btn {
  top: -3rem;
  line-height: 1.5rem;
  height: 1.5rem;
  width: 4.5rem;
  p {
    font-size: 12px;
  }
}

.ai-inform-txt {
  font-size: 0.6rem;
  margin: 0;
}
.footer-label {
  p {
    font-size: 0.6rem;
  }
}
.guide-wrapper {
  bottom: 1rem;
  right:  0rem;
  width: 2rem;
  height: 2rem;
  img {
    width: 0.7rem;
    height: 0.7rem;
  }
}

}
@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

}